@mixin z-depth-0() {
  box-shadow: none !important;
}
@mixin z-depth-1() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
@mixin z-depth-1-half() { 
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
@mixin z-depth-2() {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
@mixin z-depth-3() {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
@mixin z-depth-4() {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
@mixin z-depth-5() {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}
@mixin z-depth-5-half() {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
@mixin z-depth-mini() {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.06), 0 3px 1px -2px rgba(0, 0, 0, 0.1);
}
@mixin z-depth-6() {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 7px 0 rgba(0, 0, 0, 0.07), 0 3px 3px -3px rgba(0, 0, 0, 0.08);
}
@mixin z-depth-large() {
  box-shadow: 0 20px 30px 15px rgba(0, 0, 0, 0.1), 0 10px 40px 20px rgba(0, 0, 0, 0.08), 0 10px 15px -8px rgba(0, 0, 0, 0.1);
}
@mixin z-depth-half() {
  box-shadow: 0 10px 15px 8px rgba(0, 0, 0, 0.1), 0 5px 20px 10px rgba(0, 0, 0, 0.08), 0 5px 8px -4px rgba(0, 0, 0, 0.1);
}
@mixin z-depth-menu() {
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.05), 0 6px 20px 10px rgba(0, 0, 0, 0.02), 0 6px 10px -6px rgba(0, 0, 0, 0.05);
}

@mixin z-depth-btn() {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}