@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/lato/Lato-normal-100.woff2) format('woff2'), url(../fonts/lato/Lato-normal-100.woff) format('woff'), url(../fonts/lato/Lato-normal-100.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/lato/Lato-normal-300.woff2) format('woff2'), url(../fonts/lato/Lato-normal-300.woff) format('woff'), url(../fonts/lato/Lato-normal-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/lato/Lato.eot);
  src: local('Lato'), url(../fonts/lato/Lato.eot?#iefix) format('embedded-opentype'), url(../fonts/lato/Lato.woff2) format('woff2'), url(../fonts/lato/Lato.woff) format('woff'), url(../fonts/lato/Lato.svg#Lato) format('svg'), url(../fonts/lato/Lato.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/lato/Lato-normal-700.woff2) format('woff2'), url(../fonts/lato/Lato-normal-700.woff) format('woff'), url(../fonts/lato/Lato-normal-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/lato/Lato-normal-900.woff2) format('woff2'), url(../fonts/lato/Lato-normal-900.woff) format('woff'), url(../fonts/lato/Lato-normal-900.ttf) format('truetype');
}

