@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-200.ttf) format('truetype'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-200.woff2) format('woff2'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-200.woff) format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-300.ttf) format('truetype'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-300.woff2) format('woff2'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-300.woff) format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.eot);
  src: local('Source Sans Pro'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.ttf) format('truetype'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.woff2) format('woff2'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.eot?#iefix) format('embedded-opentype'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.woff) format('woff'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-400.svg#SourceSansPro) format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-700.ttf) format('truetype'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-700.woff2) format('woff2'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-700.woff) format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/source-sans-pro/Source-Sans-Pro-normal-900.ttf) format('truetype'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-900.woff2) format('woff2'), url(../fonts/source-sans-pro/Source-Sans-Pro-normal-900.woff) format('woff');
}

