/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Design by : webspeciman.com
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import 'fontawesome'; 
@import 'bootstrap/functions';

@import 'style/poppins';
@import 'style/source-sans-pro';
@import 'style/lato';

// @import 'bootstrap/variables';
@import 'variables';
@import 'bootstrap/mixins';
@import 'bootstrap/utilities';

// Layout & components
@import 'bootstrap/root';
@import 'bootstrap/reboot';

@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/containers';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/accordion';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/toasts';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/spinners';
@import 'bootstrap/offcanvas';
@import 'bootstrap/placeholders';

// Helpers
@import 'bootstrap/helpers';
// Utilities
@import 'bootstrap/utilities/api';
// third pirty Core CSS
// @import 'sweetalert2';

// Core extanded SCSS
@import 'jquery-ui';
// @import 'apexcharts';
@import 'swiper';
// @import 'lightgallery'; 
// @import 'animate-on-scroll'; 
// @import 'style/owl-carousel';  