// Устанавливаю box-sizing в content-box,
// для всех классов с префиксом "ui-".
//
// Это нужно для правильного вычесления
// размеров виджетов jquery-ui
[class^='ui-'] {
  &,
  &:after,
  &:before {
    box-sizing: content-box;
  }
}

.ui-widget {
  
  @include ui-font($ui-widget-font-size, $ui-widget-line-height, $ui-widget-font-weight);

  &-content {
    border: $ui-widget-border-width solid $ui-widget-content-border-color;
    background: $ui-widget-content-background;
    color: $ui-widget-content-color;

    a {
      color: $ui-widget-content-color;
    }
  }

  &-header {
    border: $ui-widget-border-width solid $ui-widget-header-border-color;
    color: $ui-widget-header-color;
    font-weight: $ui-widget-header-font-weight;
    background: $ui-widget-header-background;

    a {
      color: $ui-widget-header-color;
    }
  }

  // Overlays
  &-overlay {
    background: $ui-widget-overlay-background;
    opacity: $ui-widget-overlay-opacity;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-shadow {
    @include ui-box-shadow($ui-widget-shadow);
  }
}

.ui-front {
  @include ui-box-shadow($ui-front-shadow);
}